<div class="row" style="height: 100vh;">
  <div class="col-md-6">
    <div class="card" style="width: 100%;">
      <img class="card-img-top" height="350px"
        src="https://images.unsplash.com/photo-1579407364450-481fe19dbfaa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1489&q=80"
        alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">Leaderboard</h5>
        <p class="card-text">This view allows you to see the top 3 for the current gameweek.</p>
        <a routerLink="/winners" routerLinkActive="active" class="btn btn-primary">Go To Leaderboard</a>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card" style="width: 100%;">
      <img class="card-img-top" height="350px"
        src="https://images.unsplash.com/photo-1588431558645-316675e000de?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1351&q=80"
        alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">Add Bulk Fixtures</h5>
        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
          content.</p>
        <a routerLink="/fixtures" routerLinkActive="active" class="btn btn-primary">Go To Fixtures</a>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card" style="width: 100%;">
      <img class="card-img-top" height="350px"
        src="https://images.unsplash.com/photo-1599204606395-ede983387d21?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
        alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">Edit Gameweek Fixtures</h5>
        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
          content.</p>
        <a routerLink="/gameweek-fixtures" routerLinkActive="active" class="btn btn-primary">Edit Gameweek Fixtures</a>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card" style="width: 100%;">
      <img class="card-img-top" height="350px"
        src="https://images.unsplash.com/photo-1528201240592-15b3056985f0?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
        alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
          content.</p>
        <a routerLink="/leagues" routerLinkActive="active" class="btn btn-primary">Set League ID</a>
      </div>
    </div>
  </div>

</div>