import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { RestfulServiceService } from './services/restful-service/restful-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'YOLO101';
  // token:any = this.restFul.authenticate();

  constructor( public restFul: RestfulServiceService, private cookieService: CookieService ) { 
    this.restFul.authenticate().subscribe((res:any) => {
      console.log({response: res});
      this.cookieService.set("token", res.data.token, 1);
    });
  }

}

