
<div class="row" style="max-height: 100%;height: 100%; overflow-y:hidden">
    <div class="col-md-12">
        <div class="row" style="height:8vh;">
            <div class="col-md-8">
                <a routerLink="/" routerLinkActive="active" class="btn btn-primary">Dashboard</a>
            </div>
            <div class="col-md-4">
                
            </div>
        </div>
    </div>
    <div class="col-md-8" style="max-height: 900px;height: 900px; overflow-y:scroll">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">
                        <div class="btn-group mr-3">
                            <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                                <button class="btn btn-outline-danger" ngbDropdownToggle>League {{leagueID}}</button>
                                <div class="dropdown-menu" ngbDropdownMenu>
                                    <button (click)="setTheLeague(3691)" ngbDropdownItem>PSL</button>
                                    <button (click)="setTheLeague(3456)" ngbDropdownItem>EPL</button>
                                    <!-- <button (click)="setTheLeague(2771)" ngbDropdownItem>UEFA</button> -->
                                    <!-- <button (click)="setTheLeague(403)" ngbDropdownItem>EURO 2020</button> -->
                                </div>
                            </div>  
                        </div>
                    </th>
                </tr>
            </thead>
        </table>

        <table class="table table-striped" >
            
            <thead>
                <tr>
                    <th scope="col">Home Team</th>
                    <th scope="col" sortable="name">Away Team</th>
                    <th scope="col" sortable="area">Date</th>
                    <th scope="col" sortable="population">Add</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of fixtures">
                    <td>{{row.homeTeam}}</td>
                    <td>{{row.awayTeam}}</td>
                    <td>{{convertToDate(row.fixtureDate)}}</td>
                    <td><button (click)="addToFixtureList(row, row.fixtureId)">ADD</button></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-md-4">
        
          <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">
                        <div class="btn-group mr-3">
                            <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                                <button class="btn btn-outline-danger" ngbDropdownToggle>Gameweek {{gameWeek}}</button>
                                <div class="dropdown-menu" ngbDropdownMenu>
                                    <button *ngFor="let week of weeks" (click)="setTheWeek(week)" ngbDropdownItem>{{week}}</button>
                                </div>
                            </div> 
                            <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                                <button class="btn btn-outline-danger" ngbDropdownToggle>Current {{current}}</button>
                                <div class="dropdown-menu" ngbDropdownMenu>
                                    <button ngbDropdownItem (click)="isCurrent(true)">True</button>
                                    <button ngbDropdownItem (click)="isCurrent(false)">False</button>
                                </div>
                            </div>  
                        </div>
                    </th>
                </tr>
            </thead>
        </table>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Selected Matches</th>
                </tr>
            </thead>
        </table>
        <mat-chip-list #chipList aria-label="Fruit selection">
            <mat-chip
              *ngFor="let game of fixturesToSend"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(game)">
              {{game.homeTeam}} v. {{game.awayTeam}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        </mat-chip-list>
        <div class="btn-group btn-group-toggle" style="margin-top:20px;margin-bottom:20px;">
            <label class="btn-danger" ngbButtonLabel>
              <input type="checkbox" ngbButton (click)="fixturesToSend = []"> Clear
            </label>
            <label class="btn-success" ngbButtonLabel>
                <input type="checkbox" ngbButton (click)="updateGameWeek()">Set Gameweek
              </label>
            <label class="btn-warning" ngbButtonLabel>
              <input type="checkbox" ngbButton  (click)="updateFixtures()" > Save Fixtures
            </label>
          </div>
          <div width="100%">
              <iframe width="100%" height="900px" src="https://www.psl.co.za/matchcentre"></iframe>
          </div>
    </div>
</div>


<!-- <mat-chip-list #chipList aria-label="Fruit selection">
    <mat-chip
      *ngFor="let fruit of fixturesToSend"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(fruit)">
      {{fruit.homeTeam}} v. {{fruit.awayTeam}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
</mat-chip>
</mat-chip-list>
<mat-divider></mat-divider>
<input type="number" #gameWeek value="gameWeek"  min="1">


<mat-radio-group aria-label="Select an option">
    <mat-radio-button value="" (click)="isCurrent()">Make Current</mat-radio-button>
</mat-radio-group>
<section>
  <div class="example-button-row">
    <button mat-stroked-button color="primary" (click)="updateFixtures()">submit</button>
  </div>
</section> -->