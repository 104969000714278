import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FixturesComponent } from './fixtures/fixtures.component';
import { LoginComponent } from './login/login.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { HttpClientModule } from "@angular/common/http";
// Bootstrap stuff
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FixturesFilterPipe } from './shared/fixtures-filter.pipe';
import { GameweekFixturesComponent } from './gameweek-fixtures/gameweek-fixtures.component';
import { WinnersComponent } from './winners/winners/winners.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { LeaderboardComponent } from './leaderboard/leaderboard/leaderboard.component';
import { LeaguesComponent } from './leagues/leagues/leagues.component';

// eSaZbdxv1ET5nZneEUw81DlAUb53 - Beel2
// Jq3zTVJTO4QMdCKRDPUiNl6XeIG3 - Thabo_1
// lV7R9UF2kZODWacQeeIUazn9m4k1 - Keneilwe52
// Szmpj21FAZXdgcXfJwuGuLvbmPv2 - ntuthuko
// VHaNO7yhlDVdE9mEQ4NyIGAVvKp1 - asivemkwambi

@NgModule({
  declarations: [
    AppComponent,
    FixturesComponent,
    LoginComponent,
    FixturesFilterPipe,
    GameweekFixturesComponent,
    WinnersComponent,
    DashboardComponent,
    LeaderboardComponent,
    LeaguesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatGridListModule,
    MatListModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatChipsModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [FixturesFilterPipe]
})
export class AppModule { }
