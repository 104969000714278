<div class="row">
    <div class="col-md-12">
        <div class="row" style="height:8vh;">
            <!-- <div class="col-md-8">
                <a routerLink="/" routerLinkActive="active" class="btn btn-primary">Dashboard</a>
            </div> -->
            <div class="col-md-12">
                <table class="table table-striped" >
            
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Username</th>
                            <th scope="col" sortable="name">Points</th>
                            <th scope="col" sortable="area">Position</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of users">
                            <td><img [src]="row.userImageUrl" width="33" /></td>
                            <td>{{row.username}}</td>
                            <td>{{row.totalPoints}}</td>
                            <td>{{row.overallPosition}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>