import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { FixturesComponent } from './fixtures/fixtures.component';
import { GameweekFixturesComponent } from './gameweek-fixtures/gameweek-fixtures.component';
import { LeaguesComponent } from './leagues/leagues/leagues.component';
import { LoginComponent } from './login/login.component';
import { WinnersComponent } from './winners/winners/winners.component';


const routes: Routes = [
  { path: "", component: DashboardComponent, pathMatch: "full" },
  { path: "fixtures", component: FixturesComponent, pathMatch: "full" },
  { path: "gameweek-fixtures", component: GameweekFixturesComponent, pathMatch: "full" },
  { path: "winners", component: WinnersComponent, pathMatch: "full" },
  { path: "login", component: LoginComponent, pathMatch: "full" },
  { path: "dashboard", component: DashboardComponent, pathMatch: "full" },
  { path: "leagues", component: LeaguesComponent, pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
