import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef,
  EventEmitter, Input, Output, QueryList, ViewChildren} from '@angular/core';
import { RestfulServiceService } from '../services/restful-service/restful-service.service';
import { Observable } from "rxjs";
import {FormControl} from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
// import { Fixture } from '../shared/fixture'


export interface Fixture {
  awayTeam: string;
  homeTeam: string;
  id: number;
  leagueId: number;
  processed:number;
  fixtureDate: string;
  fixtureId: number;
  gameWeek: string;
  goalsAwayTeam: number;
  goalsHomeTeam: number;
  status: string;
}



@Component({
  selector: 'app-fixtures',
  templateUrl: './fixtures.component.html',
  styleUrls: ['./fixtures.component.scss']
})
export class FixturesComponent implements OnInit {
  fixtures: Fixture[];
  fixturesToSend = [];
  fixtureIDToSend = [];
  removable = true;
  selectable = true;
  gameWeek = "";
  current:boolean = false;
  columndefs: any = ["homeTeam","awayTeam", "fixture_date", "add"];
  queryParam: string = '';
  leagueID: number;
  hometeamFilter:string = "";
  weeks:any = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39];
  // @ViewChild('gameWeek') public gameWeek:ElementRef;
  constructor( public restFul: RestfulServiceService, private route: ActivatedRoute, private cookieService: CookieService) { 
    
  }
  filter = new FormControl('');
  token:any;

  ngOnInit(): void {
    this.restFul.authenticate().subscribe((res:any) => {
      // this.cookieService.set("token", res.data.token, 1);
      console.log(res.data.token);
    });
    
    this.route.queryParams.subscribe(params => {
      this.queryParam = params['val'];
      console.log(this.queryParam)
    });
  }

  setTheLeague(id) {
    this.leagueID = id;
    this.getFixtures(id);
  }
  convertToDate(item) {
    var newDate = new Date(Number(item+"000"));
    var m =  ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    var date = newDate.getDate() + " " + m[newDate.getMonth()]  + " " + newDate.getFullYear();
    return date;
  }
  isCurrent(current: boolean){
    this.current = current;
  }
  addToFixtureList(fixture, fixtureID){
    this.fixturesToSend.push(fixture);
    this.fixtureIDToSend.push(fixtureID);
    console.log(this.fixturesToSend);
  }

  setTheWeek(theWeek) {
    this.gameWeek = theWeek.toString();
  }
  getFixtures(id) {
    this.restFul.getFixtures(id, this.token).subscribe((data: any) => {
      this.fixtures = data.data;
      console.log(this.fixtures);
    });
  }

  getWinners(id) {
    this.restFul.getFixtures(id, this.token).subscribe((data: any) => {
      this.fixtures = data;
      console.log(this.fixtures);
    });
  }

// 2-10 - 11- 18

  updateFixtures() {
    this.restFul.updateFixtures(this.fixtureIDToSend, this.gameWeek, this.leagueID).subscribe((data: any) => {
      console.log(this.fixtureIDToSend, data);
      this.fixturesToSend = [];
      this.fixtureIDToSend = [];
      if(this.current === true) {
        console.log(this.current)
        this.restFul.updateCurrentGameWeek(this.gameWeek, this.leagueID).subscribe((data: any) => {
          console.log("Gameweek "+this.gameWeek,data);
        })
      }
      
    });
  }
  
  updateGameWeek(){
    this.restFul.updateCurrentGameWeek(this.gameWeek, this.leagueID).subscribe((data: any) => {
      console.log("Gameweek "+this.gameWeek,data);
    })
  }

  remove(item: any): void {
    const index = this.fixturesToSend.indexOf(item);
    const indexTwo = this.fixtureIDToSend.indexOf(item.fixture_id);

    if (index >= 0 && indexTwo>= 0) {
      this.fixturesToSend.splice(index, 1);
      this.fixtureIDToSend.splice(indexTwo, 1);
      console.log(this.fixtureIDToSend)
    }
  }

}
