import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef,
  EventEmitter, Input, Output, QueryList, ViewChildren, PipeTransform} from '@angular/core';
import { RestfulServiceService } from '../services/restful-service/restful-service.service';
import { Observable } from "rxjs";
import {FormControl} from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';


export interface Fixture {
  awayTeam: string;
  homeTeam: string;
  id: number;
  league_id: number;
  processed:number;
  fixture_date: string;
  fixture_id: number;
  gameWeek: string;
  goalsAwayTeam: number;
  goalsHomeTeam: number;
  status: string;
  isCurrentGameWeek: number;
}

@Component({
  selector: 'app-gameweek-fixtures',
  templateUrl: './gameweek-fixtures.component.html',
  styleUrls: ['./gameweek-fixtures.component.scss']
})

export class GameweekFixturesComponent implements OnInit {
  fixtures: Fixture[];
  allFixtures: Fixture[];
  constructor(public restFul: RestfulServiceService, private route: ActivatedRoute, private cookieService: CookieService) {

   }
  token:any;
  currentGameweek:string;
  currentToken = this.cookieService.get("token");

  ngOnInit(): void {
    this.getFixtures("3021", this.token);
  }

  getFixtures(id, token) {
    this.restFul.getGameweekFixtures(id, token).subscribe((data: any) => {
      this.fixtures = data.data;
      console.log(this.fixtures);
    });
    this.restFul.getFixtures(id, token).subscribe((data: any) => {
      this.allFixtures = data;
      console.log(data);
    });
  }

  filterFixtureOfType(type:number){
    
    // this.fixtures.forEach(element => {
    //   if(element.isCurrentGameWeek == type) {
    //     this.currentGameweek = element.gameWeek;
    //   }
    // });
    
    return this.fixtures.filter(x => x.isCurrentGameWeek == type);
  }

  addFixture(id) {
    this.restFul.updateFixturesSingleFixture(3021,"16",true,id).subscribe((response: any )=> {
      console.log(response)
      this.restFul.getGameweekFixtures("3021", this.token).subscribe((data: any) => {
        this.fixtures = data.data;
        console.log(this.fixtures);
      });
    })
  }

  removeFixture() {
    this.restFul.getGameweekFixtures("3021", this.token).subscribe((data: any) => {
      this.fixtures = data.data;
      console.log(this.fixtures);
    });
  }

  convertToDate(item) {
    var newDate = new Date(Number(item+"000"));
    var m =  ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    var date = newDate.getDate() + " " + m[newDate.getMonth()]  + " " + newDate.getFullYear();
    return date;
  }
}
