import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

// const LOGGING_API: string = "https://soccer101-customfeeds.appspot.com";
// const FIXTURES_ENDPOINT: string = `${LOGGING_API}/getAllFixturesForLeague?leagueId=3021`;

export class RestfulServiceService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }
  public host = 'https://soccer101-core.ew.r.appspot.com/api/admin';
  public hostTwo = 'https://soccer101-core.ew.r.appspot.com';

  public logBatch = { logs: [] };
  private tempToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjU4ODIiLCJuYmYiOjE2MTUzNzkwMTQsImV4cCI6MTYxNTQ2NTQxNCwiaWF0IjoxNjE1Mzc5MDE0fQ.qEHf82OCnxPvzSMCX8A3Zx61XgEfqFHLBXn58XFIJUg";

  
  authenticate() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "application/json",
      }),
    }
    return this.http.post("https://soccer101-core.ew.r.appspot.com/api/users/authenticate", { username: 'MrDanP', password: 'FmgAdmin422790!'}, httpOptions)
  }
  

  getFixtures(id,token) {
      console.log(token);
      let getToken = this.cookieService.get("token");
      console.log({token: getToken});
      let httpOptions2 = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${getToken}`
        }),
      };
      
      return this.http.get(`${this.host}/getAllFixturesForLeague?leagueId=${id}`, httpOptions2)
  }

  getGameweekFixtures(id,token) {
    let getToken = this.cookieService.get("token");
    console.log({token: getToken});
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${getToken}`
      }),
    };
    //let url = "https://soccer101-core-dev.ew.r.appspot.com/api/fixtures/getFixturesByLeagueAndCurrentRound/"
    let url = `${this.hostTwo}/api/admin/getGameWeekFixturesForLeague/`
    console.log(httpOptions);
    return this.http.get(`${url}?leagueId=${id}`, httpOptions)
      
  }


  getGameweekWinners(id) {
    let getToken = this.cookieService.get("token");
    console.log({token: getToken});
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${getToken}`
      }),
    };
    //let url = "https://soccer101-core-dev.ew.r.appspot.com/api/fixtures/getFixturesByLeagueAndCurrentRound/"
    let url = `${this.hostTwo}/api/admin/getGameWeekWinners`;
    console.log(httpOptions);
    return this.http.get(`${url}?leagueLookupName=${id}`, httpOptions)
      
  }

  updateFixturesSingleFixture(league:number,gameWeek:string,isCurrentGameWeek:boolean,fixtureId:number) {
    let getToken = this.cookieService.get("token");
    let url = `${this.hostTwo}/api/admin/addGameweekFixture?leagueId=${league}&gameWeek=${gameWeek}&isCurrentGameWeek=${isCurrentGameWeek}&fixtureId=${fixtureId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer `
      }),
    };
    return this.http.post(`${url}`, httpOptions)
  }

  updateFixtures(fixtures,gameWeek, league) {
    let getToken = this.cookieService.get("token");

    const url = `${this.host}/updateGameweekFixturesForLeague?leagueId=${league}&gameWeek=${gameWeek}`;
    console.log(url);
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${getToken}`
      }),
    };

    return this.http.post(url, fixtures, httpOptions)
    
  }

  updateCurrentGameWeek(gameWeek,league) {
    let getToken = this.cookieService.get("token");
    const url = `${this.host}/setCurrentGameWeekForLeague?leagueId=${league}&gameWeek=${gameWeek}`;
    console.log(url);
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${getToken}`
      }),
    };

    return this.http.get(url, httpOptions)
    
  }

  tmcTable() {
    let getToken = this.cookieService.get("token");
    const url = `https://soccer101-core.ew.r.appspot.com/api/adhoc/leaderboard-group?leagueId=403&groupId=1`;
    console.log(url);
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "application/json",
      }),
    };

    return this.http.get(url, httpOptions)
    
  }

}
