import { Component, OnInit } from '@angular/core';
import { RestfulServiceService } from 'src/app/services/restful-service/restful-service.service';

export interface Winner {
  leagueId: number;
  position: string;
  points: number;
  userId: string;
  username:string;
  firstName: string;
  surname: string;
  cellphone: string;
}

@Component({
  selector: 'app-winners',
  templateUrl: './winners.component.html',
  styleUrls: ['./winners.component.scss']
})
export class WinnersComponent implements OnInit {

  constructor(public restFul: RestfulServiceService) { }

  winners: Winner[];
  league = "";

  ngOnInit(): void {
  }

  getTheWinners(id){
    this.league = id;
    this.restFul.getGameweekWinners(id).subscribe((data: any) => {
      this.winners = data.data;
      console.log(this.winners);
    });
  }

}
