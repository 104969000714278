<div class="row" style="height: 100vh;">
    <div class="col-md-12">
        <div class="row" style="height:8vh;">
            <div class="col-md-8">
                <a routerLink="/" routerLinkActive="active" class="btn btn-primary">Dashboard</a>
            </div>
            <div class="col-md-4">
                <div class="btn-group">
                    <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                        <button class="btn btn-outline-danger" ngbDropdownToggle>League {{league}}</button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <button (click)="getTheWinners('PSL')" ngbDropdownItem>PSL</button>
                            <button (click)="getTheWinners('PL')" ngbDropdownItem>EPL</button>
                            <button (click)="getTheWinners('UEFA')" ngbDropdownItem>UEFA</button>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12"  style="height:92vh;">
        <table class="table table-striped" >
            
            <thead>
                <tr>
                    <th scope="col">position</th>
                    <th scope="col" sortable="name">username</th>
                    <th scope="col" sortable="area">name</th>
                    <th scope="col" sortable="population">tel.</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of winners">
                    <td>{{row.position}}</td>
                    <td>{{row.username}}</td>
                    <td>{{row.firstName}} {{row.surname}}</td>
                    <td>{{row.cellphone}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
