import { Component, OnInit } from '@angular/core';
import { RestfulServiceService } from '../../services/restful-service/restful-service.service';

export interface User {
  averagePoints: number;
  gamesPlayed: number;
  overallIndex: number;
  overallPosition: string;
  totalPoints: number;
  userId: string;
  userImageUrl: string;
  username: string;
  weekIndex: number;
  weekPoints: number;
  weekPosition: string;
}

@Component({
  selector: 'app-leagues',
  templateUrl: './leagues.component.html',
  styleUrls: ['./leagues.component.scss']
})
export class LeaguesComponent implements OnInit {
  users: User[];
  constructor( public restFul: RestfulServiceService) { 
    
  }
  ngOnInit(): void {
    this.restFul.tmcTable().subscribe((res:any) => {
      // this.cookieService.set("token", res.data.token, 1);
      this.users = res.data.items;
      console.log(res.data.items);
    });
  }

  

}
