<div class="row">
    <div class="col-md-12">
        <div class="row" style="height:8vh;">
            <div class="col-md-8">
                <a routerLink="/" routerLinkActive="active" class="btn btn-primary">Dashboard</a>
            </div>
            <div class="col-md-4">
                
            </div>
        </div>
    </div>
    <div class="col-md-8" style="height: 100vh; overflow-y:scroll;">
        
        <table class="table table-striped" >
                
            <thead>
                <tr>
                    <th scope="col">Home Team</th>
                    <th scope="col" sortable="name">Away Team</th>
                    <th scope="col" sortable="area">Date</th>
                    <th scope="col" sortable="area">Fixture id</th>
                    <th scope="col" sortable="area"></th>
                    <th scope="col" sortable="area"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of allFixtures" >
                    <td>{{row.homeTeam}}</td>
                    <td>{{row.awayTeam}}</td>
                    <td>{{convertToDate(row.fixture_date)}}</td>
                    <td>{{row.fixture_id}}</td>
                    <td><button (click)="addFixture(row.fixture_id)" class="btn btn-warning">ADD</button></td>
                    <td><button (click)="removeFixture()" class="btn btn-danger">Remove</button></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-md-4">
        <!-- <b>{{currentToken}}</b> -->
        <table class="table table-striped" >
                
            <thead>
                <tr>
                    <th scope="col">Home Team</th>
                    <th scope="col" sortable="name">Away Team</th>
                    <th scope="col" sortable="area">Date</th>
                    <th scope="col" sortable="area">Gameweek</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of filterFixtureOfType(1)" >
                    <td>{{row.homeTeam}}</td>
                    <td>{{row.awayTeam}}</td>
                    <td>{{row.fixtureDate}}</td>
                    <td>{{row.gameWeek}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    
</div>
